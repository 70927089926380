/* https://github.com/marvelapp/devices.css */

.marvel-device {
  display: inline-block;
  position: relative;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.marvel-device .screen {
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 3;
  background: white;
  overflow: hidden;
  display: block;
  border-radius: 1px;
  -webkit-box-shadow: 0 0 0 3px #111;
  box-shadow: 0 0 0 3px #111;
}

.marvel-device .top-bar,
.marvel-device .bottom-bar {
  height: 3px;
  background: black;
  width: 100%;
  display: block;
}

.marvel-device .middle-bar {
  width: 3px;
  height: 4px;
  top: 0px;
  left: 90px;
  background: black;
  position: absolute;
}

.marvel-device.iphone-x {
  width: 375px;
  height: 812px;
  padding: 26px;
  background: #fdfdfd;
  -webkit-box-shadow: inset 0 0 11px 0 black;
  box-shadow: inset 0 0 11px 0 black;
  border-radius: 66px;
}

.marvel-device.iphone-x .overflow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 66px;
  overflow: hidden;
}

.marvel-device.iphone-x .shadow {
  border-radius: 100%;
  width: 90px;
  height: 90px;
  position: absolute;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 60%
  );
}

.marvel-device.iphone-x .shadow--tl {
  top: -20px;
  left: -20px;
}

.marvel-device.iphone-x .shadow--tr {
  top: -20px;
  right: -20px;
}

.marvel-device.iphone-x .shadow--bl {
  bottom: -20px;
  left: -20px;
}

.marvel-device.iphone-x .shadow--br {
  bottom: -20px;
  right: -20px;
}

.marvel-device.iphone-x:before {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute;
  top: 5px;
  content: '';
  left: 5px;
  border-radius: 61px;
  background: black;
  z-index: 1;
}

.marvel-device.iphone-x .inner-shadow {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  overflow: hidden;
  left: 10px;
  border-radius: 56px;
  -webkit-box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
  box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
  z-index: 1;
}

.marvel-device.iphone-x .inner-shadow:before {
  -webkit-box-shadow: inset 0 0 20px 0 #ffffff;
  box-shadow: inset 0 0 20px 0 #ffffff;
  width: 100%;
  height: 116%;
  position: absolute;
  top: -8%;
  content: '';
  left: 0;
  border-radius: 200px / 112px;
  z-index: 2;
}

.marvel-device.iphone-x .screen {
  border-radius: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.marvel-device.iphone-x .top-bar,
.marvel-device.iphone-x .bottom-bar {
  width: 100%;
  position: absolute;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
}

.marvel-device.iphone-x .top-bar {
  top: 80px;
}

.marvel-device.iphone-x .bottom-bar {
  bottom: 80px;
}

.marvel-device.iphone-x .volume,
.marvel-device.iphone-x .volume:before,
.marvel-device.iphone-x .volume:after,
.marvel-device.iphone-x .sleep {
  width: 3px;
  background: #b5b5b5;
  position: absolute;
}

.marvel-device.iphone-x .volume {
  left: -3px;
  top: 116px;
  height: 32px;
}

.marvel-device.iphone-x .volume:before {
  height: 62px;
  top: 62px;
  content: '';
  left: 0;
}

.marvel-device.iphone-x .volume:after {
  height: 62px;
  top: 140px;
  content: '';
  left: 0;
}

.marvel-device.iphone-x .sleep {
  height: 96px;
  top: 200px;
  right: -3px;
}

.marvel-device.iphone-x .camera {
  width: 6px;
  height: 6px;
  top: 9px;
  border-radius: 100%;
  position: absolute;
  left: 154px;
  background: #0d4d71;
}

.marvel-device.iphone-x .speaker {
  height: 6px;
  width: 60px;
  left: 50%;
  position: absolute;
  top: 9px;
  margin-left: -30px;
  background: #171818;
  border-radius: 6px;
}

.marvel-device.iphone-x .notch {
  position: absolute;
  width: 210px;
  height: 30px;
  top: 26px;
  left: 108px;
  z-index: 4;
  background: black;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.marvel-device.iphone-x .notch:before,
.marvel-device.iphone-x .notch:after {
  content: '';
  height: 8px;
  position: absolute;
  top: 0;
  width: 8px;
}

.marvel-device.iphone-x .notch:after {
  background: radial-gradient(
    circle at bottom left,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  left: -8px;
}

.marvel-device.iphone-x .notch:before {
  background: radial-gradient(
    circle at bottom right,
    transparent 0,
    transparent 70%,
    black 70%,
    black 100%
  );
  right: -8px;
}
